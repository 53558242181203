import { lazy } from 'react'

const routes = [
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/callcenter/companies',
    Component: lazy(() => import('pages/callcenter/companies')),
    exact: true,
  },
  {
    path: '/callcenter/callnow',
    Component: lazy(() => import('pages/callcenter/callnow')),
    exact: true,
  },
  {
    path: '/callcenter/called',
    Component: lazy(() => import('pages/callcenter/called')),
    exact: true,
  },
  {
    path: '/callcenter/emailsent',
    Component: lazy(() => import('pages/callcenter/emailsent')),
    exact: true,
  },
  {
    path: '/callcenter/angebote',
    Component: lazy(() => import('pages/callcenter/angebote')),
    exact: true,
  },
  {
    path: '/callcenter/reminder',
    Component: lazy(() => import('pages/callcenter/reminder')),
    exact: true,
  },
  {
    path: '/callcenter/terminset',
    Component: lazy(() => import('pages/callcenter/terminset')),
    exact: true,
  },
  {
    path: '/callcenter/interested',
    Component: lazy(() => import('pages/callcenter/interested')),
    exact: true,
  },
  {
    path: '/callcenter/notinterested',
    Component: lazy(() => import('pages/callcenter/notinterested')),
    exact: true,
  },
  {
    path: '/callcenter/deactive',
    Component: lazy(() => import('pages/callcenter/deactive')),
    exact: true,
  },
  {
    path: '/callcenter/undecided',
    Component: lazy(() => import('pages/callcenter/undecided')),
    exact: true,
  },
  {
    path: '/callcenter/sold',
    Component: lazy(() => import('pages/callcenter/sold')),
    exact: true,
  },
  {
    path: '/callcenter/demo',
    Component: lazy(() => import('pages/callcenter/demo')),
    exact: true,
  },
  {
    path: '/callcenter/notanswer',
    Component: lazy(() => import('pages/callcenter/notanswer')),
    exact: true,
  },
  {
    path: '/callcenter/group',
    Component: lazy(() => import('pages/callcenter/group')),
    exact: true,
  },
  {
    path: '/callcenter/numbererror',
    Component: lazy(() => import('pages/callcenter/numbererror')),
    exact: true,
  },
  {
    path: '/callcenter/mail',
    Component: lazy(() => import('pages/callcenter/mail')),
    exact: true,
  },
  {
    path: '/callcenter/turk',
    Component: lazy(() => import('pages/callcenter/turk')),
    exact: true,
  },
  {
    path: '/callcenter/error',
    Component: lazy(() => import('pages/callcenter/error')),
    exact: true,
  },
  {
    path: '/questionAnswers',
    Component: lazy(() => import('pages/questionAnswers')),
    exact: true,
  },
  {
    path: '/dashboard/statistics',
    Component: lazy(() => import('pages/dashboard/statistics')),
    exact: true,
  },

  {
    path: '/logs',
    Component: lazy(() => import('pages/callCenterActivityLogs')),
    exact: true,
  },
  {
    path: '/logs2',
    Component: lazy(() => import('pages/callCenterActivityLogs/user')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('pages/userss')),
    exact: true,
  },
  {
    path: '/calendar',
    Component: lazy(() => import('pages/calendar')),
    exact: true,
  },
  {
    path: '/callRecords',
    Component: lazy(() => import('pages/callRecords')),
    exact: true,
  },
  {
    path: '/mouseActivitys',
    Component: lazy(() => import('pages/mouseActivitys')),
    exact: true,
  },
  {
    path: '/calendar2',
    Component: lazy(() => import('pages/calendar2')),
    exact: true,
  },
  {
    path: '/timesheet',
    Component: lazy(() => import('pages/timesheet')),
    exact: true,
  },
  {
    path: '/timesheet/user',
    Component: lazy(() => import('pages/timesheet/user')),
    exact: true,
  },
  {
    path: '/outlook-ural',
    Component: lazy(() => import('pages/outlook')),
    exact: true,
  },
  {
    path: '/outlook-murat',
    Component: lazy(() => import('pages/outlook/murat')),
    exact: true,
  },
  {
    path: '/outlook-esra',
    Component: lazy(() => import('pages/outlook/esra')),
    exact: true,
  },
  {
    path: '/customers',
    Component: lazy(() => import('pages/customers')),
    exact: true,
  },
  {
    path: '/contracts',
    Component: lazy(() => import('pages/contracts')),
    exact: true,
  },
  {
    path: '/callRecords2',
    Component: lazy(() => import('pages/callRecords2')),
    exact: true,
  },
  {
    path: '/personals',
    Component: lazy(() => import('pages/personals')),
    exact: true,
  },
  {
    path: '/company/:id',
    Component: lazy(() => import('pages/callcenter/editpage2')),
    exact: true,
  },
  // {
  //   path: '/company/:id',
  //   Component: lazy(() => import('pages/callcenter/editpage')),
  //   exact: true,
  // },
  {
    path: '/offers',
    Component: lazy(() => import('pages/offers')),
    exact: true,
  },
  {
    path: '/edit-offer/:id',
    Component: lazy(() => import('pages/offers/edit-offer')),
    exact: true,
  },
  {
    path: '/travels',
    Component: lazy(() => import('pages/travels')),
    exact: true,
  },
  {
    path: '/contracts/edit/:id',
    Component: lazy(() => import('pages/contracts/edit-contract')),
    exact: true,
  },
  {
    path: '/contracts/offer/:id',
    Component: lazy(() => import('pages/contracts/create-from-offer')),
    exact: true,
  },
  {
    path: '/singleTokens',
    Component: lazy(() => import('pages/singleTokens')),
    exact: true,
  },
  {
    path: '/provisions',
    Component: lazy(() => import('pages/provisions')),
    exact: true,
  },
  {
    path: '/separechnung',
    Component: lazy(() => import('pages/sepaRechnungs')),
    exact: true,
  },
  {
    path: '/timesheetLockeds',
    Component: lazy(() => import('pages/timesheetLockeds')),
    exact: true,
  },
  {
    path: '/contractSigneds',
    Component: lazy(() => import('pages/contractSigneds')),
    exact: true,
  },
  {
    path: '/contractSigneds2',
    Component: lazy(() => import('pages/contractSigneds/user')),
    exact: true,
  },
  {
    path: '/documents',
    Component: lazy(() => import('pages/documents')),
    exact: true,
  },
  {
    path: '/vorlages',
    Component: lazy(() => import('pages/vorlages')),
    exact: true,
  },
  {
    path: '/events',
    Component: lazy(() => import('pages/userEvents/index2')),
    exact: true,
  },
  {
    path: '/appSettings/:id',
    Component: lazy(() => import('pages/appSettingss')),
    exact: true,
  },
  {
    path: '/cloudRechnungs',
    Component: lazy(() => import('pages/cloudRechnungs')),
    exact: true,
  },
  {
    path: '/languages',
    Component: lazy(() => import('pages/language')),
    exact: true,
  },
  {
    path: '/companymanagers',
    Component: lazy(() => import('pages/companymanagers/index')),
    exact: true,
  },
  {
    path: '/companymanagers/fill',
    Component: lazy(() => import('pages/companymanagers/fill')),
    exact: true,
  },
  {
    path: '/callcenter/sbmkunden',
    Component: lazy(() => import('pages/callcenter/sbmkunden')),
    exact: true,
  },
  {
    path: '/contractTexts',
    Component: lazy(() => import('pages/contractTexts')),
    exact: true,
  },
  {
    path: '/ftp',
    Component: lazy(() => import('pages/ftp')),
    exact: true,
  },
  {
    path: '/dailyreport',
    Component: lazy(() => import('pages/calendar-user')),
    exact: true,
  },
]

export default routes
