const actions = {
  REQUESTING: 'DASHBOARD/REQUESTING',

  RESPONSE_ERROR: 'DASHBOARD/RESPONSE_ERROR',

  LIST_DASHBOARD: 'DASHBOARD/LIST',
  RESET_DASHBOARD: 'DASHBOARD/RESET',

  FETCH_DASHBOARD: 'DASHBOARD/FETCH',
  RE_FETCH_DASHBOARD: 'DASHBOARD/REFRESH',

  FETCH_CHART_FOR_CALL: 'DASHBOARD/CALL_CHART',
  FETCH_CHART_FOR_CALL_RECIEVED: 'DASHBOARD/CALL_CHART_RECIEVED',

  FETCH_TOTAL_COUNTS: 'DASHBOARD/TOTAL_COUNTS',
  FETCH_TOTAL_COUNTS_RECIEVED: 'DASHBOARD/TOTAL_COUNTS_RECIEVED',

  FETCH_TODAY_CALLS: 'DASHBOARD/TODAY_CALLS',
  FETCH_TODAY_CALLS_RECIEVED: 'DASHBOARD/TODAY_CALLS_RECIEVED',

  FETCH_WHAT_THINKS: 'DASHBOARD/WHAT_THINKS',
  FETCH_WHAT_THINKS_RECIEVED: 'DASHBOARD/WHAT_THINKS_RECIEVED',

  FETCH_TIMES: 'DASHBOARD/FETCH_TIMES',
  FETCH_TIMES_RECIEVED: 'DASHBOARD/FETCH_TIMES_RECIEVED',

  FETCH_TIMES_CALL_ACITITY: 'DASHBOARD/FETCH_TIMES_CALL_ACITITY',
  FETCH_TIMES_CALL_ACITITY_RECIEVED: 'DASHBOARD/FETCH_TIMES_CALL_ACITITY_RECIEVED',

  FETCH_TIMESHEET: 'DASHBOARD/FETCH_TIMESHEET',
  FETCH_TIMESHEET_RECIEVED: 'DASHBOARD/FETCH_TIMESHEET_RECIEVED',

  UPDATE_TIMESHEET: 'DASHBOARD/UPDATE_TIMESHEET',
  UPDATE_TIMESHEET_UODATE_RECIEVED: 'DASHBOARD/UPDATE_TIMESHEET_UODATE_RECIEVED',

  UPDATE_DURATIONS: 'DASHBOARD/UPDATE_DURATIONS',
  UPDATE_DURATIONS_RECIEVED: 'DASHBOARD/UPDATE_DURATIONS_RECIEVED',

  FETCH_RECALL_LIST: 'DASHBOARD/FETCH_RECALL_LIST',
  FETCH_RECALL_LIST_RECIEVED: 'DASHBOARD/FETCH_RECALL_LIST_RECIEVED',

  FETCH_REMINDER_LIST: 'DASHBOARD/FETCH_REMINDER_LIST',
  FETCH_REMINDER_LIST_RECIEVED: 'DASHBOARD/FETCH_REMINDER_LIST_RECIEVED',

  FETCH_ACTIVITY_TIMES: 'DASHBOARD/FETCH_ACTIVITY_TIMES',
  FETCH_ACTIVITY_TIMES_RECIEVED: 'DASHBOARD/FETCH_ACTIVITY_TIMES_RECIEVED',

  FETCH_CALL_TIMES: 'DASHBOARD/FETCH_CALL_TIMES',
  FETCH_CALL_TIMES_RECIEVED: 'DASHBOARD/FETCH_CALL_TIMES_RECIEVED',

  FETCH_CALL_AND_ACTIVITY_TIMES: 'DASHBOARD/FETCH_CALL_AND_ACTIVITY_TIMES',
  FETCH_CALL_AND_ACTIVITY_TIMES_RECIEVED: 'DASHBOARD/FETCH_CALL_AND_ACTIVITY_TIMES_RECIEVED',

  FETCH_timesheetsAgain: 'DASHBOARD/FETCH_timesheetsAgain',
  FETCH_timesheetsAgain_recieved: 'DASHBOARD/FETCH_timesheetsAgain_recieved',

  FETCH_timesheetLock: 'DASHBOARD/FETCH_timesheetLock',
  FETCH_timesheetLock_recieved: 'DASHBOARD/FETCH_timesheetLock_recieved',

  OPEN_SENDEMAIL_MODAL: 'OPEN_SENDEMAIL_MODAL',
  OPEN_SENDEMAIL_MODAL_RECIEVED: 'OPEN_SENDEMAIL_MODAL_RECIEVED',

  CLOSE_SENDEMAIL_MODAL: 'CLOSE_SENDEMAIL_MODAL',
  CLOSE_SENDEMAIL_MODAL_RECIEVED: 'CLOSE_SENDEMAIL_MODAL_RECIEVED',

  LOAD_SENDEMAIL_PARAMETERS: 'LOAD_SENDEMAIL_PARAMETERS',
  LOAD_SENDEMAIL_PARAMETERS_RECIEVED: 'LOAD_SENDEMAIL_PARAMETERS_RECIEVED',

  SENDEMAIL_TIMESHEET: 'SENDEMAIL_TIMESHEET',
  SENDEMAIL_TIMESHEET_RECIEVED: 'SENDEMAIL_TIMESHEET_RECIEVED',

  FETCH_CreateTimesheet: 'DASHBOARD/FETCH_CreateTimesheet',
  FETCH_CreateTimesheet_RECIEVED: 'DASHBOARD/FETCH_CreateTimesheet_RECIEVED',

  FETCH_CALLED_STATISTIC: 'DASHBOARD/FETCH_CALLED_STATISTIC',
  FETCH_CALLED_STATISTIC_RECIEVED: 'DASHBOARD/FETCH_CALLED_STATISTIC_RECIEVED',
}

export default actions
