import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'

import {
  find,
  find2,
  save,
  remove,
  getFilterResults,
  getRemindersForMe,
  getNotFilledManagerCompanys,
  getNotFilledManagerCompanysCount,
} from '../../services/companyIGZ'

let lastStorageName = ''
let lastMethodName = ''

function* fetchCompanyIGZEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_COMPANYIGZID,
    result,
  })
}

function* fetchCompanyIGZEdit2({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find2, id)
  yield put({
    type: actions.EDIT_COMPANYIGZID,
    result,
  })
}

function* fetchCompanyIGZSave({ values, silent }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null && silent === undefined) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  if (silent === undefined) {
    yield put({
      type: actions.RE_FETCH_COMPANYIGZIDS,
      storageName: lastStorageName,
      methodName: lastMethodName,
    })
  }
}

function* fetchCompanyIGZDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_COMPANYIGZID_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_COMPANYIGZIDS,
    methodName: lastMethodName,
    storageName: lastStorageName,
  })
}

function* fetchFilterResults({
  size,
  index,
  searchTerm,
  orderBy,
  name1,
  name2,
  name3,
  city,
  storageName,
  methodName,
  postalCode,
  firmaOrigin,
  hauptsitz,
  telefon,
  username,
  email,
  reminderDate,
}) {
  console.log('fetchFilterResults', storageName, methodName, reminderDate)
  if (!methodName) return
  lastStorageName = storageName
  lastMethodName = methodName
  const ts = localStorage.getItem(storageName)
  if (ts !== undefined && ts != null) {
    const fe = JSON.parse(ts)
    console.log('fetchFilterResults fe', fe)
    if (fe != null) {
      if (size === undefined) size = fe.size || size
      if (index === undefined) index = fe.index || index
      if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
      if (orderBy === undefined) orderBy = fe.orderBy || orderBy
      name1 = fe.name1 || name1
      name2 = fe.name2 || name2
      telefon = fe.telefon || telefon
      name3 = fe.name3 || name3
      city = fe.city || city
      postalCode = fe.postalCode || postalCode
      firmaOrigin = fe.firmaOrigin || firmaOrigin
      hauptsitz = fe.hauptsitz || hauptsitz
      username = fe.username || username
      email = fe.email || email
      reminderDate = fe.reminderDate || reminderDate
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      storageName,
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        name1,
        name2,
        name3,
        city,
        telefon,
        postalCode,
        firmaOrigin,
        hauptsitz,
        username,
        email,
        reminderDate,
      }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getFilterResults,
    size,
    index,
    searchTerm,
    orderBy,
    name1,
    name2,
    name3,
    city,
    methodName,
    postalCode,
    firmaOrigin,
    hauptsitz,
    telefon,
    username,
    email,
    reminderDate,
  )
  yield put({
    type: actions.LIST_COMPANYIGZID,
    result,
  })
}
function* outlookOpenModal() {
  yield put({
    type: actions.OUTLOOK_MODAL_OPEN_RECIEVED,
  })
}

function* outlookCloseModal() {
  yield put({
    type: actions.OUTLOOK_MODAL_CLOSE_RECIEVED,
  })
}

function* fetchReminderForMe() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getRemindersForMe)
  yield put({
    type: actions.FETCH_REMAINDER_FOR_ME_RECIEVED,
    result,
  })
  localStorage.setItem('reminder', JSON.stringify(result))
}

function* emailSendFromList() {
  // yield put({
  //   type: actions.EMAIL_SEND_FROM_LIST_RECIEVED,
  // })
}

function* reset() {
  yield put({
    type: actions.RESET_COMPANYIGZID,
  })
}

function* fetchNotFilledManagerCompanys({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('update_list'))
    if (fe != null) {
      size = fe.size || 150
      index = fe.index || 0
    } else {
      size = 100
      index = 0
    }
  }
  localStorage.setItem('update_list', JSON.stringify({ size, index }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getNotFilledManagerCompanys, size, index)
  yield put({
    type: actions.getNotFilledManagerCompanys_recieved,
    result,
  })
}

function* m2anagerUpdated({ data }) {
  yield put({
    type: actions.REQUESTING,
  })
  yield put({
    type: actions.managerUpdated_recieved,
    payload: data,
  })
}

function* get2NotFilledManagerCompanysCount() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getNotFilledManagerCompanysCount)
  yield put({
    type: actions.getNotFilledManagerCompanysCount_recieved,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.managerUpdated, m2anagerUpdated),
    takeEvery(actions.getNotFilledManagerCompanysCount, get2NotFilledManagerCompanysCount),
    takeEvery(actions.getNotFilledManagerCompanys, fetchNotFilledManagerCompanys),
    takeEvery(actions.FETCH_REMAINDER_FOR_ME, fetchReminderForMe),
    takeEvery(actions.EMAIL_SEND_FROM_LIST, emailSendFromList),
    takeEvery(actions.OUTLOOK_MODAL_OPEN, outlookOpenModal),
    takeEvery(actions.OUTLOOK_MODAL_CLOSE, outlookCloseModal),
    takeEvery(actions.FETCH_COMPANYIGZID, fetchCompanyIGZEdit),
    takeEvery(actions.FETCH_COMPANYIGZID2, fetchCompanyIGZEdit2),
    takeEvery(actions.SAVE_COMPANYIGZID, fetchCompanyIGZSave),
    takeEvery(actions.DELETE_COMPANYIGZID, fetchCompanyIGZDelete),
    takeEvery(actions.RE_FETCH_COMPANYIGZIDS, fetchFilterResults),
    takeEvery(actions.FETCH_COMPANYIGZIDS_FILTER, fetchFilterResults),

    takeEvery('RESET_COMPANY_EDIT', reset),
  ])
}
